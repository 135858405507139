// ----------------------------------------------------------------
// baguatteBox.js init moved to tpl-img-gallery.php
// since it's ONLY loaded on that page.
// ----------------------------------------------------------------

// ----------------------------------------------------------------
// slick slideshow js init moved to tpl-slideshow.php
// since it's ONLY loaded on that page.
// ----------------------------------------------------------------

// ----------------------------------------------------------------
// tab js init moved to tpl-tabs.php
// since it's ONLY loaded on that page.
// ----------------------------------------------------------------

// ----------------------------------------------------------------
// gridder js init moved to tpl-team-profile.php
// since it's ONLY loaded on that page.
// ----------------------------------------------------------------



(function($){
	$(document).ready(function(){
        //mobile - open lateral menu clicking on the menu icon

	function closeNav() {
		$('html, body').css({
		    overflow: 'auto',
		    height: 'auto'
		});
		$('.hamburger').removeClass('is-active nav-is-visible');
		$('.cd-main-header').removeClass('nav-is-visible');
		$('.cd-primary-nav').removeClass('nav-is-visible');
        //$('.to-slide').removeClass('nav-is-visible');
		$('.menu-item-has-children ul').addClass('is-hidden');
		$('.menu-item-has-children a').removeClass('selected');
		$('.moves-out').removeClass('moves-out');
		// $('.to-slide').removeClass('nav-is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
		// 	$('body').removeClass('overflow-hidden');
		// });
		$('.to-slide').removeClass('nav-is-visible');
		$('body').removeClass('overflow-hidden');

	};

	$('.hamburger').on('click', function(event){
		event.preventDefault();
		$('html').css({'overflow' : 'hidden', 'height' : '100vh'});
		$('body').css({'overflow-y': 'hidden','height' : '100%'});
		if( $('.cd-main-content').hasClass('nav-is-visible') ) {
			closeNav();
			$('.cd-overlay').removeClass('is-visible');
            $(this).removeClass('is-active nav-is-visible');
		} else {
			$(this).addClass('is-active nav-is-visible');
			$('.cd-primary-nav').addClass('nav-is-visible');
			$('.cd-main-header').addClass('nav-is-visible');
            //$('.to-slide').addClass('nav-is-visible');
			// $('.to-slide').addClass('nav-is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
			// 	$('body').addClass('overflow-hidden');
			// });
			$('.to-slide').addClass('nav-is-visible');
			$('body').addClass('overflow-hidden');
			$('.cd-overlay').addClass('is-visible');

			// close the refine search
			$("#panel").removeClass( "display" );
			$("body").removeAttr("style");
		}
	});

	//close lateral menu on mobile
	$('.cd-overlay').on('swiperight', function(){
		if($('.cd-primary-nav').hasClass('nav-is-visible')) {
			closeNav();
			$('.cd-overlay').removeClass('is-visible');
		}
	});
	$('.nav-on-left .cd-overlay').on('swipeleft', function(){
		if($('.cd-primary-nav').hasClass('nav-is-visible')) {
			closeNav();
			$('.cd-overlay').removeClass('is-visible');
		}
	});
	$('.cd-overlay').on('click', function(){
		closeNav();
		$('.cd-overlay').removeClass('is-visible');
	});


	$("#back").click(function(){
		$("#panel").removeClass( "display" );
		$("body").removeAttr("style");
	});
	$("#flip").click(function(){
		$("#panel").addClass( "display" );
		$("body").css({'overflow' : 'hidden','height' : '100vh'});
	});



	//prevent default clicking on direct children of .cd-primary-nav
	$('.cd-primary-nav').children('.menu-item-has-children').children('a').on('click', function(event){
		event.preventDefault();
	});
	//open submenu
	$('.menu-item-has-children').children('a').on('click', function(event){
		// if( !checkWindowWidth() ) event.preventDefault();
    	event.preventDefault();
		var selected = $(this);
		var last_cat_allowed = 'ul.sub-menu > li.menu-item > ul.sub-menu> li.menu-item > ul.sub-menu';
		if( selected.next(last_cat_allowed).is('ul') ) {
			//console.log('last');
			if(selected.next(last_cat_allowed).hasClass('is-hidden')){
				selected.addClass('selected').next('ul').removeClass('is-hidden').addClass('is-visible').end();
			}
			else if (selected.next(last_cat_allowed).hasClass('is-visible')){
				selected.addClass('selected').next('ul').removeClass('is-visible').addClass('is-hidden').end();
			}
		}
		else if( selected.next('ul').hasClass('is-hidden') ) {
			//console.log('normal');
			//desktop version only
			selected.addClass('selected').next('ul').removeClass('is-hidden').end().parent('.menu-item-has-children').parent('ul').addClass('moves-out');
			selected.parent('.menu-item-has-children').siblings('.menu-item-has-children').children('ul').addClass('is-hidden').end().children('a').removeClass('selected');
			$('.cd-overlay').addClass('is-visible');
		} else {
			selected.removeClass('selected').next('ul').addClass('is-hidden').end().parent('.menu-item-has-children').parent('ul').removeClass('moves-out');
			$('.cd-overlay').removeClass('is-visible');
		}
	});

	//submenu items - go back link
	$('.go-back').on('click', function(){
		$(this).parent('ul').addClass('is-hidden').parent('.menu-item-has-children').parent('ul').removeClass('moves-out');
	});



	});// end of document ready


	$(window).resize(function() {

	});// end of window resize


  // INIT COMPANY SLIDER
  $(document).ready(function(){
		if ( $( ".company_slider" ).length ) {
			$('.company_slider').slick({
	      dots: true,
				arrows: true,
	    });
		}

  });


})(jQuery);

/**
 *  Handles the shrinking of the header
 *  @return null Doesn't return anything.
 */
function shrinkHeader() {
    var stuckFlag = false,
        body = document.querySelector("body"),
        shrinkOn = 400,
        scrollClass = "hasScrolled";
    window.addEventListener('scroll', function() {
        var distanceY = window.pageYOffset || document.documentElement.scrollTop;
        if (distanceY > shrinkOn && !stuckFlag) {
			      body.classList.add(scrollClass);
            stuckFlag = true;
        } else if (distanceY <= shrinkOn && stuckFlag) {
            body.classList.remove(scrollClass);
            stuckFlag = false;
        }
    });
}
window.onload = shrinkHeader();

// remove hover effect on touch devices
var touch = 'ontouchstart' in document.documentElement
            || navigator.maxTouchPoints > 0
            || navigator.msMaxTouchPoints > 0;

if (touch) { // remove all :hover stylesheets
    try { // prevent exception on browsers not supporting DOM styleSheets properly
        for (var si in document.styleSheets) {
            var styleSheet = document.styleSheets[si];
            if (!styleSheet.rules) continue;

            for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                if (!styleSheet.rules[ri].selectorText) continue;

                if (styleSheet.rules[ri].selectorText.match(':hover')) {
                    styleSheet.deleteRule(ri);
                }
            }
        }
    } catch (ex) {}
}

// document.getElementById('hamburger-menu').addEventListener('click', function () {
//     this.classList.toggle("is-active");
// });
(function($){
	// Accordion 1
	$(document).ready(function(){
		var acc = document.getElementsByClassName("accordion");
		var btn_close = document.getElementsByClassName("contact__form_close");
		var different_panel = document.getElementsByClassName("panel");
		var i;



		for (i = 0; i < acc.length; i++) {

			btn_close[i].onclick = function() {
				for (j = 0; j < acc.length; j++) {
				    var panel = different_panel[j];
						if (panel.style.maxHeight){
							acc[j].classList.toggle("active");
				      panel.style.maxHeight = null;
				    }
				}
			}
		  acc[i].onclick = function() {

				for (j = 0; j < acc.length; j++) {
				    var panel = different_panel[j];
						if (panel.style.maxHeight){
							acc[j].classList.toggle("active");
				      panel.style.maxHeight = null;
				    }
				}

		    this.classList.toggle("active");
		    var panel = this.parentNode.parentNode.parentNode.nextElementSibling;
		    if (panel.style.maxHeight){
		      panel.style.maxHeight = null;
		    } else {
		      panel.style.maxHeight = panel.scrollHeight + "px";
		    }
		  }
		}
	});

	/// sorting posts
	$(function(){
      // bind change event to select
      $('#sort_post').on('change', function () {
          var url =  $(this).val(); // get selected value
          if (url) { // require a URL
              window.location = url; // redirect
          }
          return false;
      });
    });

		// Search filter mobile
		// $(document).ready(function(){
		// 	// if ( $( "#panel" ).length ) {
		// 	// 	$("#back").click(function(){
		// 	// 		$("#panel").removeClass( "display" );
		// 	// 		$("body").removeAttr("style");
		// 	// 	});
		// 	// 	$("#flip").click(function(){
		// 	// 		$("#panel").addClass( "display" );
		// 	// 		$("body").css({'overflow' : 'hidden','height' : '100vh'});
		// 	// 	});
		// 	// };
		//
		// 	// $("#back").click(function(){
		// 	// 	$("#panel").removeClass( "display" );
		// 	// 	$("body").removeAttr("style");
		// 	// });
		// 	// $("#flip").click(function(){
		// 	// 	$("#panel").addClass( "display" );
		// 	// 	$("body").css({'overflow' : 'hidden','height' : '100vh'});
		// 	// });
		// });


		// SAME HEIGHT
		$(document).ready(function(){
			function fixHeight(elem)
			{
					var maxHeight = 0;
					jQuery(elem).css('height', 'auto');
					jQuery(elem).each(function ()
					{
							if (jQuery(this).height() > maxHeight) {
									maxHeight = jQuery(this).height();
							}
					});
					jQuery(elem).height(maxHeight);
			}
			if ( $( ".card-category__item" ).length ) {
		    fixHeight('.card-category__item');
			}
			if ( $( ".card-company__item" ).length ) {
				//fixHeight('.card-company__item');
			}
		});
})(jQuery);
